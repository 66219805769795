import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import { Form } from 'semantic-ui-react';
import FormBuilder, { setConfigFieldProperty } from 'react-dj-forms-builder';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';
import pt_BR from "date-fns/locale/pt-BR";

import utils from '../../core/utils';
import AppMessageBox from '../../components/general/appMessageBox/appMessageBox';
import usuarioClient from '../../apiClients/login/usuarioClient';

import './scss/confirmacaoExternaDados.scss';
import InputAghuse from 'components/fields/formsBuilderCustoms/aghuse/inputAghuse';
import DatePickerAghuse from 'components/fields/formsBuilderCustoms/aghuse/datePickerAghuse';

const CPF_MASK = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
const PHONE_MASK = ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
const DATE_MASK = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];


const AGHUSE_NOTIFY = {
    SHOW_MSG: "SHOW_MESSAGE_ON_DIALOG",
    CLOSE_TAB: "CLOSE_CURRENT_TAB",
}

const isValidExternalParams = (config) => {
    return genesysUtils.typeCheck.isString(config?.fingerprint) && genesysUtils.typeCheck.isString(config?.jwtServiceToken);
}

const autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd/mm/yyyy');

const readExternalParams = (queryString) => {
    const sp = new URLSearchParams(queryString);
    const config = {
        fingerprint: sp.get("fp"),
        jwtServiceToken: sp.get("jwt")
    };
    return isValidExternalParams(config) ? config : null;
}

const buildFields = (fieldKeys, dadosUsuario) => {
    const fields = {};
    (fieldKeys || []).forEach((key) => {

        let value = (dadosUsuario[key] || "");
        let errorMessage = null;
        let disabled = false;

        if ('dtNascimento' === key) {
            value = moment(dadosUsuario[key]).format('DD/MM/YYYY');
            disabled = !genesysUtils.typeCheck.isUndefinedOrNull(dadosUsuario["pacCodigo"]);     

        } else if ('cpf' === key) {
            disabled = true;
        }  

        fields[key] = { value, errorMessage, disabled };        
    });
    
    return fields;
}

const ConfirmacaoExternaDados = (props) => {

    const [erroInicializacao, setErroInicializacao] = useState(null);
    const [firstJwtServiceToken, setFirstJwtServiceToken] = useState(null);
    const [jwtServiceToken, setJwtServiceToken] = useState(null);
    const [fingerprint, setFingerprint] = useState(null);
    const [dadosUsuario, setDadosUsuario] = useState(null);
    const [formConfig, setFormConfig] = useState(null);
    const [fields, setFields] = useState(null);
    const [disableButtons, setDisableButtons] = useState({confirmacaoDados: false, resetSenha: false });

    const _initialize = useCallback(() => {
        const ep = readExternalParams(props.location?.search);

        if (ep) {
            setFingerprint(ep.fingerprint);
            setFirstJwtServiceToken(ep.jwtServiceToken);
        }
    }, [props]);

    const _fetchDadosUsuario = useCallback(() => {

        usuarioClient.obterDadosConfirmacaoExterna(firstJwtServiceToken, fingerprint, {}, {})
            .then((response) => {
                if (!genesysUtils.typeCheck.isObject(response.data)) {
                    throw new Error("Invalid API service response,");
                }

                let config = response.data.formConfig;

                setConfigFieldProperty(config, ["cpf"], "masked.mask", CPF_MASK);
                setConfigFieldProperty(config, ["telefoneCelular"], "masked.mask", PHONE_MASK);
                setConfigFieldProperty(config, ["dtNascimento"], "masked.mask", DATE_MASK);
                setConfigFieldProperty(config, ["dtNascimento"], "masked.pipe", autoCorrectedDatePipe);
                setConfigFieldProperty(config, ["dtNascimento"], "datePicker.locale", pt_BR);

                setFormConfig(config);                
                setDadosUsuario(response.data);

                setDisableButtons({
                    confirmacaoDados: !response.data.servidorPodeConfirmarCadastro,
                    resetSenha: !response.data.servidorPodeResetarSenha
                });

                setJwtServiceToken(response.data.updateServiceToken);
            })
            .catch((error) => {
                console.error("Erro na requisição:", error);
                let message;
                if(error.code === 'ERR_NETWORK') {
                    message = 'Erro de comunicação, serviço temporariamente indisponível.'

                } else {
                    if (error?.response?.status === 400 && error?.response?.data?.code === 'USUARIO_INEXISTENTE') {
                        _notificarAghuseUsuarioInexistente(error.response.data.message);
                        return;
                    }
                    message = genesysUtils.typeCheck.isString(error?.response?.data) ? error.response.data : "Ocorreu um erro ao buscar informações do usuário."
                }
                setErroInicializacao({
                    header: "Ops!",
                    message 
                });
            });

    }, [fingerprint, firstJwtServiceToken]);    
   
    const _onFormChange = (newFieldValues) => {
        setFields(newFieldValues);
        setDisableButtons({...disableButtons, confirmacaoDados: false});
    }

    const _getFormValuesToSubmit = () => {
        const submitValues = {};
        for (var key in fields) {
            const fld = fields[key];
            submitValues[key] = key === "dtNascimento" ? utils.convertDateToEpochAtMidday(fld.value) : fld.value?.trim();
        }

        submitValues['pacCodigo'] = dadosUsuario['pacCodigo'];
        submitValues['userId'] = dadosUsuario['userId'];

        return submitValues;
    }

    const _findKeyByBackendName = (backendName) => {
        
        const fieldConfigs = formConfig.pages[0].groups[0].fields;

        for (var key in fieldConfigs) {
            if (backendName === fieldConfigs[key].backendName) {
                return key;
            }
        }
        return null;
    }

    const _updateFieldErrors = (err) => {        
        const errorData = err.response.data;
        const fieldData = {...fields}; 

        Object.keys(errorData).forEach(errorKey => {

            const fieldKey = _findKeyByBackendName(errorKey);

            if (fieldKey) {
                fieldData[fieldKey].errorMessage = errorData[errorKey];
            }                   
            
        });               

        setFields(fieldData); 
    }

    const _confirmarCadastro = () => {

        const formValues = _getFormValuesToSubmit();

        usuarioClient.confirmarDados(formValues, jwtServiceToken, fingerprint, {}, {})
            .then((res) => {
                
                _notificarAghuse("Confirmação de Cadastro realizada com sucesso.", "info");

                setDisableButtons({...disableButtons, confirmacaoDados: true});
            })
            .catch((err) => {
                console.error(err);
                
                if (err.status) {
                    
                    if (_verificarTokenExpirado(err.status)) {
                        return;
                    }

                    _updateFieldErrors(err);

                    _notificarAghuse("Erro ao Confirmar Cadastro", "error");

                } else {
                    _notificarAghuse("Erro na comunicação com o servidor", "error");
                }
            });
    }
    
    const _resetarSenha = () => {

        const requestData = {
            pacCodigo: dadosUsuario.pacCodigo,
            userId: dadosUsuario.userId
        };

        usuarioClient.resetarSenha(requestData, jwtServiceToken, fingerprint, {}, {})
            .then((response) => {
                _notificarAghuse("Senha alterada para a data de nascimento do usuário. (ddMMyyyy)", "info");
                setDisableButtons({...disableButtons, resetSenha: true});
            })
            .catch((err) => { 
                if (err.status) {
                    
                    if (_verificarTokenExpirado(err.status)) {
                        return;
                    }
                    _notificarAghuse("Erro ao resetar a senha.", "error");

                } else {
                    _notificarAghuse("Erro na comunicação com o servidor.", "error");
                }
            });
    }
    
    const _verificarTokenExpirado = (statusCode) => {
        if (statusCode === 401) {
            _notificarAghuseSessaoExpirada();
            return true;
        }
        return false;
    }

    const _notificarAghuseSessaoExpirada = () => {
        const target = window.parent ? window.parent : window;

        target.postMessage({type: AGHUSE_NOTIFY.SHOW_MSG, severity: "error", message: "Sessão de confirmação de dados expirada."}, "*");
        target.postMessage({type: AGHUSE_NOTIFY.CLOSE_TAB}, "*");
    }

    const _notificarAghuseUsuarioInexistente = (errorMessage) => {
        const target = window.parent ? window.parent : window;

        target.postMessage({type: AGHUSE_NOTIFY.SHOW_MSG, severity: "error", message: errorMessage}, "*");
        target.postMessage({type: AGHUSE_NOTIFY.CLOSE_TAB}, "*");
    }

    const _notificarAghuse = (messageText, messageSeverity) => {
        const target = window.parent ? window.parent : window;
        target.postMessage({type: AGHUSE_NOTIFY.SHOW_MSG, severity: messageSeverity, message: messageText}, "*");
    }
    
    useEffect(_initialize, [_initialize]);

    useEffect(() => {

        if (!firstJwtServiceToken || !fingerprint) {
            return;
        }

        _fetchDadosUsuario();

    }, [firstJwtServiceToken, fingerprint, _fetchDadosUsuario]);

    useEffect(() => {
        if (!formConfig || !dadosUsuario) {
            return;
        }
        setFields(buildFields(Object.keys(formConfig.pages[0].groups[0].fields), dadosUsuario));

    }, [formConfig, dadosUsuario]);

    return (
        <div className="confirmacao-externa-dados-wrapper">

            {erroInicializacao &&
                <AppMessageBox id="msgBoxErroInicializacao"
                    className="error"
                    messageData={erroInicializacao} />
            }            
            {dadosUsuario && !erroInicializacao &&
                <>
                    <fieldset className='confirmar-dados-wifi'>

                        <legend align="left">{formConfig.pages[0].groups[0].title}</legend>

                        <Form className="edit-form card-box.forms">

                            {fields != null &&
                                <FormBuilder
                                    blockFieldUpdate={false}
                                    disableClearErrorOnFieldChange={false}
                                    config={formConfig}
                                    fields={fields}
                                    page={0}
                                    className={`form-content`}
                                    onChange={_onFormChange}
                                    overrideFieldRender={{
                                        'input': InputAghuse
                                    }}
                                    customComponents={{
                                        dtNascimento: DatePickerAghuse
                                    }}
                                />
                            }

                            <div className="form-actions">
                                <button id="buttonResetarSenha" className="app-form-button aghuse-button" onClick={_resetarSenha}
                                        disabled={disableButtons?.resetSenha}>
                                    Resetar Senha
                                </button>
                                <button id="buttonConfirmarCadastro" className="app-form-button aghuse-button" onClick={_confirmarCadastro}
                                        disabled={disableButtons?.confirmacaoDados}>
                                    Confirmar Cadastro
                                </button>
                            </div>

                        </Form>
                    </fieldset>
                </>
            }
        </div>
    );
}

export default ConfirmacaoExternaDados;