/*
** @name: Meu Clínicas - vincularCadastro
** @author: 
** @date:
** @description: Módulo para confirmação de telefone não validados no cadastro
** 
** @update: Março 2021 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Atualizado para novo layout da aplicação e funcionamento com cards.
*/

import React, { Component } from 'react';
import FormBuilder, { setConfigFieldProperty } from 'react-dj-forms-builder';
import { Form } from 'semantic-ui-react';
import { upperCase } from '@hcpa-react-components/string-utils';
import { useAppConfigContext, AppCustomMessage } from '@hcpa-react-components/app-customization';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import utils from '../../core/utils.js';
import sessionStorageManager from '../../core/sessionStorageManager.js';
import { useAuthContext } from '../../core/authContext.js';
import { useAppControllerContext } from '../../core/appControllerContext.js';
import { APP_SERVICE_LIST } from '../../core/appServiceList.js';
import { DISABLE_LOADING } from '../../core/appRequest.js';

import AppCardModuleBasicWrapper from '../../components/general/appCardModuleBasicWrapper/appCardModuleBasicWrapper.js';
import AppConfirmationDialog from '../../components/general/appConfirmationDialog/appConfirmationDialog.js';
import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';

import EmailUsuario from './emailUsuario.js';
import SenhaUsuario from './senhaUsuario.js';

import loginClient from 'apiClients/login/loginClient.js';
import usuarioClient from '../../apiClients/login/usuarioClient.js';


// Import module styles
import './vincularCadastro.scss'; 

// Import form configuration
import formVincularCadastro from './vincularCadastro.json';


const AUTOCLOSE_TIMEOUT = 10000;

const VincularCadastro = (props) => {
    const authContext = useAuthContext();
    const appControllerContext = useAppControllerContext();
    const appContextConfig = useAppConfigContext().getContextConfig();
    return(
        <VincularCadastroImplem
            authContext={authContext}
            appControllerContext={appControllerContext}
            appContextConfig={appContextConfig}
            {...props}
        />
    )
}

class VincularCadastroImplem extends Component {
    constructor(props) {
        super(props);

        const { linkableUser, userLinked } = this.props.appControllerContext.methods.doGetCurrentCardModuleParameters() || {};

        this.state = {
            linkableUser,
            formConfig: this._configureForm(formVincularCadastro, linkableUser),
            fields: null,
            erroVinculacao: null,
            vinculacaoConcluida: userLinked ? true : false,
            refreshTokenError: false,
            responseWithError: false
        };
    }

    _buildRequest = () => {
        const request = {};
        const user = this.props.authContext.properties.user;
        const { emailUsuario, senhaUsuario } = this.state.fields || {};
        request.pacCodigo = user.pacCodigo;
        request.emailUsuario = emailUsuario ? emailUsuario.value : null;
        request.senhaUsuario = senhaUsuario ? senhaUsuario.value : null;

        return request;
    }

    _configureForm = (config, userData) => {
        if(genesysUtils.typeCheck.isObject(userData) && userData.email) {
            setConfigFieldProperty(config, ["emailUsuario"], "value", userData.email);
        }
        return config;
    }

    _handleClose = () => {
        this.props.appControllerContext.methods.doCardFadeOut();
    }

    _handleForcedLogout = () => {
        this.props.appControllerContext.methods.doLogout(true);
    }

    _handleFormUpdate = (fields) => {
        this.setState({ fields: fields });
    }

    _handleVincular = () => {
        const request = this._buildRequest();
        usuarioClient.vincularUsuarios(request, {}, {})
            .then(res => {              
                const result = res.data;
                if(result.sucesso) {
                    this._validateResponse(result, false); // On fail an error will be thrown and handled by error block
                    this._setErroVinculacao(null);
                    this.setState({ 
                        vinculacaoConcluida: true ,
                        refreshTokenError: !result.token ? true : false
                    }, () => {
                        this._updateUserInformation(result);
                        this._updateModuleParameters();
                    });
                } else {
                    this._setErroVinculacao(result.erroVinculacao ? result.erroVinculacao : "Por favor, verifique o correto preenchimento do formulário.");
                }
            })
            .catch(err => {
                if(err.responseValidationError) {
                    this.setState({ responseWithError: true });
                    return;
                }
                this._setErroVinculacao("Ops!, ocorreu um erro processando sua solicitação.");
            });
    }

    _setErroVinculacao = (message) => {
        this.setState({ erroVinculacao: message });
    }

    _updateModuleParameters = () => { // Update parameters to reflect link success
        this.props.appControllerContext.methods.doSetCardModuleParameters(null, { 
            linkableUser: null,
            userLinked: true 
        });
    }

    _updateUserInformation = (newData) => {
        // Atualiza dados do usuário
        const { authContext } = this.props;
        const user = authContext.properties.user;
        const fingerprint = sessionStorageManager.auth.getFingerprint();
        user.nome = newData.nome;
        user.email = newData.email;
        user.cpf = newData.cpf;
        user.rg = newData.rg;
        user.celular = newData.celular;
        user.dthrAceiteTermos = newData.dthrAceiteTermos;
        user.dthrConfirmaTelefone = newData.dthrConfirmaTelefone;
        user.criadoEm = newData.criadoEm;

        const newToken = newData.token;
        sessionStorageManager.auth.store(newToken, fingerprint, user);
        authContext.methods.refresh();

        // App Integration: remove app token e obtem um novo se for o caso
        if(window.rnIntegration) {
            if(user.appLoginTokenStored) { // atualiza token quando usuário possui autologin do app ativado
                const removeAppToken = () => window.rnIntegration.removeAppLogin();
                if(newToken) {
                    loginClient.asyncTokenRefresh(user.pacCodigo, true, {}, DISABLE_LOADING)
                        .then(res => {
                            const tokenApp = res.data;
                            window.rnIntegration.saveAppLogin(tokenApp, fingerprint, user.pacCodigo);
                        })
                        .catch(err => removeAppToken()); // on error, remove any existing token
                } else {
                    removeAppToken();
                }
            }
        }
    }

    _validateForm = () => {
        const { fields } = this.state;
        return fields && fields.senhaUsuario && fields.senhaUsuario.value ? true : false;
    }

    _validateResponse = (response, testToken) => {
        const { linkableUser } = this.state;
        if(!response.nome || response.email!==linkableUser.email || (testToken && !response.token)) {
            throw Object.assign(new Error("[vincularCadastro] Invalid response data."), { 
                responseValidationError: true,
                invalidToken : (testToken && !response.token) ? true : false
            });
        }
    }

    componentDidMount() {
        const { linkableUser, vinculacaoConcluida } = this.state;
        if(!vinculacaoConcluida && (!genesysUtils.typeCheck.isObject(linkableUser) || !linkableUser.email)) {
            console.error("Usuário para vinculação não informado ou já utilizado.");
            this._handleClose();
        }
    }

    componentDidUpdate() {
        const requireLogout = this.state.refreshTokenError || this.state.responseWithError ? true : false;
        if(!this.closeTimeoutId && this.state.vinculacaoConcluida && !requireLogout) {
            this.closeTimeoutId = utils.setAutomaticCardClose(this.props.appControllerContext, APP_SERVICE_LIST.VINCULAR_CADASTRO, AUTOCLOSE_TIMEOUT);
            this.props.appControllerContext.methods.doSetEventOnBeforeCloseModule(() => { clearTimeout(this.closeTimeoutId); });
        }
    }

    render() {
        const { linkableUser, vinculacaoConcluida, refreshTokenError, responseWithError } = this.state;
        if(!genesysUtils.typeCheck.isObject(linkableUser) && !vinculacaoConcluida) {
            return null;
        }

        const user = this.props.authContext.properties.user;
        const { email, usuarioValidado } = linkableUser || {};
        const message =  vinculacaoConcluida ? 
            <AppCustomMessage messageId="vincular-cadastro_sucesso" missingParameter={undefined} params={null} />
            :
            <AppCustomMessage 
                messageId={usuarioValidado ? "vincular-cadastro_validado" : "vincular-cadastro_nao-validado"}
                missingParameter={undefined} 
                params={[ email ]}
            />;
        const mensagemErro = vinculacaoConcluida ? null : this.state.erroVinculacao;

        return(
            <AppCardModuleBasicWrapper wrapperName="vincular-cadastro">

                { (responseWithError || refreshTokenError) &&
                <AppConfirmationDialog
                    title="Atenção"
                    message={<AppCustomMessage messageId={refreshTokenError ? "vincular-cadastro_erro-token-sessao" : "vincular-cadastro_erro-resposta"} missingParameter={undefined} params={null} />}
                    hideCancelButton={true}
                    onConfirm={() => this._handleForcedLogout()}
                    onCancel={() => this._handleForcedLogout()}
                />
                }

                <div className="information-section">
                    <div className="identificacao-usuario">OLÁ, {upperCase(user.nome)}</div>

                    <div className="link-user-message">
                        {message}
                    </div>

                    { (!vinculacaoConcluida && usuarioValidado) &&
                    <Form className="link-user-form">
                        <FormBuilder 
                            blockFieldUpdate={false}
                            disableClearErrorOnFieldChange={false}
                            config={this.state.formConfig}
                            fields={this.state.fields}
                            page={0}
                            className="form-content" 
                            onChange={this._handleFormUpdate}
                            customComponents={{
                                emailUsuario: EmailUsuario,
                                senhaUsuario: SenhaUsuario
                            }}
                        />
                    </Form>
                    }

                </div>

                { mensagemErro &&
                <AppMessageBox
                    id="msg-error"
                    className="error"
                    messageData={{ "message": mensagemErro }} />
                }

                <div className="main-action">
                    { (!vinculacaoConcluida && usuarioValidado) ?
                    <button
                        id="button-vincular" type="button" disabled={!this._validateForm()}
                        className="app-form-button" onClick={() => this._handleVincular()}>VINCULAR</button>
                    :
                    <button id="button-ok" type="button" className="app-form-button" onClick={() => this._handleClose()}>OK</button>
                    }
                </div>

            </AppCardModuleBasicWrapper>
        );
    }
}

export default VincularCadastro;
