/*
** @name: Meu Clínicas - appConfiguration.js
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Janeiro 2022
** @description: Componente que gera arquivo de configuracao da aplicação (aplicando o tema as configuracoes basicas)
**
** @update: Agosto 2024
** @description: Refatoramento para externalizações do tema
*/

import { genesysUtils } from '@hcpa-react-components/genesys-utils';
import { applySourceToTarget, createAppConfig, setCustomConfigurationApplied } from '@hcpa-react-components/app-customization';


// Import basic and theme configuration files
import basicAppConfig from '../configs/basicAppConfig.json';
import basicAppMessages from '../configs/basicAppMessages.json';
import basicThemeConfig from '../configs/basicThemeConfig.json';

import themeConfig from '../theme/themeConfig.json';

import utils from './utils';

const skipErrorOnMissingTargetProperty = !utils.isDev();

const asyncApplyOptionalConfig = async (target) => {
    try {
        await import('../theme/appConfig.json').then(f => applySourceToTarget(target, f?.default, true, skipErrorOnMissingTargetProperty)).catch(() => {});
        await import('../theme/appMessages.json').then(f => applySourceToTarget(target, f?.default, true, skipErrorOnMissingTargetProperty)).catch(() => {});
    } catch(e) { // ignore
    }
}

const asyncBuildAppConfiguration = async (customCfg) => {
    /* Realiza um 'merge' entre a configuração básica da aplicação aplicando o tema atual, tendo como base a configuração 
    ** da aplicação. Propriedades que existam no thema e na config básica devem obrigatoriamente ser do mesmo tipo ou nula.
    **
    ** ATENCAO: Arrays não sofrem 'merge' entre si, são apenas substituidos quando exitir na config do tema. Propositalmente uma
    ** propriedade só é copiada/aplicada se existir no destino (basic configuration) senão da um warning no console.
    */
    const appConfig = await asyncLoadAppExtendedBasicConfiguration();
    if(verifyCustomConfiguration(customCfg)) {
        applySourceToTarget(appConfig, customCfg, true, skipErrorOnMissingTargetProperty);
        setCustomConfigurationApplied(appConfig);
    }
    return appConfig;
}

const asyncLoadAppExtendedBasicConfiguration = async () => {
    const appConfig = createAppBasicConfiguration();
    await asyncApplyOptionalConfig(appConfig);
    return appConfig;
}

const createAppBasicConfiguration = () => {
    const appConfig = createAppConfig(basicAppConfig, basicAppMessages, basicThemeConfig);
    applySourceToTarget(appConfig, themeConfig, true, skipErrorOnMissingTargetProperty);
    return appConfig;
}

const verifyCustomConfiguration = (config) => {
    const { applicationConfig, applicationMessages } = config || {};
    return genesysUtils.typeCheck.isObject(applicationConfig) && genesysUtils.typeCheck.isObject(applicationMessages) ? true : false;
}

export {
    asyncBuildAppConfiguration,
    asyncLoadAppExtendedBasicConfiguration,
    createAppBasicConfiguration,
    verifyCustomConfiguration
}