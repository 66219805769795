/*
** @name: Meu Clínicas - recuperarSenha
** @author: 
** @date:
** @description: Módulo para recuperação de senha do usuario.
** 
** @update: Março 2021 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Atualizado para novo layout da aplicação e funcionamento com cards
*/

import React, { Component } from 'react';
import $ from 'jquery';
import { Form } from 'semantic-ui-react';
import { AppCustomImage, AppCustomMessage } from '@hcpa-react-components/app-customization';

import { useAppControllerContext } from '../../core/appControllerContext.js';
import { getImageThemeContext } from '../../core/appSpecificConfigHandler.js';

import AppCardModuleBasicWrapper from '../../components/general/appCardModuleBasicWrapper/appCardModuleBasicWrapper.js';
import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';
import EmailCPFLocalizador from '../../components/fields/emailCPFLocalizador/emailCPFLocalizador.js';

import usuarioClient from '../../apiClients/login/usuarioClient.js';

// Import module styles
import './recuperarSenha.scss'; 


const STEP_USER_IDENTIFICATION = 'USER_IDENTIFICATION';
const STEP_EMAIL_SENT = 'EMAIL_SENT';

const FLD_ID_CPF_EMAIL = "input-cpf-email";

const RecuperarSenha = (props) => {
    const appControllerContext = useAppControllerContext();
    return(
        <RecuperarSenhaImplem
            appControllerContext={appControllerContext}
            {...props}
        />
    )
}

class RecuperarSenhaImplem extends Component {
    constructor(props) {
        super(props);

        const { initialValue } = this.props.appControllerContext.methods.doGetCurrentCardModuleParameters() || {};
        this.state = {
            initialValue,
            currentStep: STEP_USER_IDENTIFICATION,
            STEP_USER_IDENTIFICATION: {
                cpfEmail: {
                    value: null,
                    valid: false
                }
            },
            STEP_EMAIL_SENT: {
            },
            msgErro: null
        };
    }

    _clearMessages = () => {
        this.setState({ msgErro: null });
    }

    _focusFormField = () => {
        let elem = document.getElementById(FLD_ID_CPF_EMAIL);
        if(elem) {
            $(elem).on('focus', () => { // Posiciona cusor ao final do campo quando receber o foco
                setTimeout(() => { elem.selectionStart = elem.selectionEnd = 10000; }, 0);
            });
            elem.focus();
        }
    }

    _handleClose = () => {
        this.props.appControllerContext.methods.doCardFadeOut();
    }

    _handleEmailCPFChange = (value, valid) => {
        this._clearMessages();

        let updatedStep = this.state.STEP_USER_IDENTIFICATION;
        updatedStep.cpfEmail = {
            value,
            valid
        };

        this.setState({STEP_USER_IDENTIFICATION: updatedStep});
    } 

    _recuperarSenhaPorEmail = (e) => {
        e.preventDefault(); 

        this._clearMessages();
        usuarioClient.recuperarSenhaPorEmail(this.state.STEP_USER_IDENTIFICATION.cpfEmail.value, {}, {})
            .then(res => {
                this.setState({
                    currentStep: STEP_EMAIL_SENT
                });             
            })
            .catch(err => {
                let header = null;
                let message = null;

                if (err.response && err.response.data) {
                    header = 'Atenção'
                    message = err.response.data;
                } else {
                    header = 'Ops...'
                    message = 'Ocorreu um erro ao processar sua requisição.';
                } 

                this.setState({msgErro: {header, message}}); 
            });
    }

    _validateRecoveryMethodForm = () => {
        return this.state.STEP_RECOVERY_METHOD.selectedOption !== null;
    }

    _validateUserIdentificationForm = () => {
        return this.state.STEP_USER_IDENTIFICATION.cpfEmail.valid;             
    }

    componentDidMount() {
        setTimeout(() => this._focusFormField(), 500);
    }

    render() {
        return(
            <AppCardModuleBasicWrapper wrapperName="recuperar-senha">

                <Form name="frmMain" className="content-form">
                    { this.state.currentStep === STEP_USER_IDENTIFICATION && 
                    <>
                        <div className='imagem-principal'>
                            <AppCustomImage imageContextFn={getImageThemeContext} module="recuperarSenha" imageId="esqueceu-senha" />
                        </div>

                        <div className='info-adicional'>
                            <p>Informe o CPF ou o e-mail utilizado no cadastro para prosseguir na recuperação de senha.</p>
                        </div>

                        <div className="float-label-field email-cpf">
                            <EmailCPFLocalizador 
                                id={FLD_ID_CPF_EMAIL}
                                name='cpfEmail' 
                                labelText='CPF ou E-mail'
                                initialValue={this.state.initialValue}
                                onChange={this._handleEmailCPFChange}
                                disableCPF={false}
                                disableEmail={false}
                                disableLocalizador={true}
                            />
                        </div> 

                        { (this.state.msgErro) && 
                        <AppMessageBox
                            id="msg-recuperar-senha-error"
                            className="error"
                            messageData={this.state.msgErro}
                        />
                        }

                        <div className="main-action">
                            <button 
                                id="button-recuperar"
                                className="app-form-button"
                                disabled={!this._validateUserIdentificationForm()}
                                onClick={e => this._recuperarSenhaPorEmail(e)}>
                                Recuperar
                            </button>
                        </div> 

                    </>
                    }

                    { this.state.currentStep === STEP_EMAIL_SENT && 
                    <>
                        <div className='info-principal'>
                            Sua solicitação foi enviada com sucesso!
                        </div>

                        <div className='info-adicional'>
                            <AppCustomMessage messageId="recuperar-senha_sucesso-corpo" elemType='html' />
                        </div>
                        <div className="main-action">
                            <button 
                                id="button-ok"
                                className="app-form-button"
                                onClick={() => this._handleClose()}>
                                Ok
                            </button>
                        </div> 

                    </>
                    }
                </Form>

            </AppCardModuleBasicWrapper>
        );
    }
}

export default RecuperarSenha;