import React, { Component } from 'react';
import $ from 'jquery';
import { Input } from 'semantic-ui-react';
import MaskedInput from 'react-text-mask';
import * as EmailValidator from 'email-validator';

import { cpfValidate } from '@hcpa-react-components/cpf-validator';

import validatorLocalizador from '../../../core/validatorLocalizador.js';

// Import module styles
import './emailCPFLocalizador.scss'; 


const CPF_MASK = [/\d/, /\d/, /\d/, '.',/\d/, /\d/, /\d/, '.',/\d/, /\d/, /\d/, '-', /\d/, /\d/];
const EMPTY_CPF_MASK = "__.___.___-__";

class EmailCPFLocalizador extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cpfEmailLocalizador: null,                
            onChange: props.onChange
        } 
    }

    _fixClearAfterTyping = () => {
        /* 
            Havia um bug no componente ao fazer o seguinte:
            1) User: Começa digitando número(s)
            2) App: Ativa máscara de CPF
            3) User: Vai deletando cada número até ficar vazio 
            4) Comportamento esperado: máscara de CPF some e usuário fica apto a digitar e-mail
            5) Comportamento real: aplicação continua exibindo a máscara de CPF (mesmo sem dígito)
        */
   
        $(`#${this.props.id}`).on("change paste keyup", function(e) {
            if ($(this).val() === EMPTY_CPF_MASK) {
                $(this).val('');
            }
         });
    }

    _handleChange = (value) => {
        const isValid = this._validate(value);

        this.setState({ cpfEmailLocalizador: value });

        //invocando o callback externo
        this.state.onChange(value, isValid);
    }

    _mask = (newValue) => {
        const { disableCPF } = this.props;
        return !disableCPF && this._startsWithNumber(newValue) ? CPF_MASK : false;
    }

    _startsWithNumber = (value) => {
        return /^\d+/.test(value);
    }

    _validate = (value) => {
        const { disableCPF, disableEmail, disableLocalizador } = this.props;

        if (!value) {
            return false;
        }

        if (!disableCPF && this._startsWithNumber(value)) {
            return cpfValidate(value);
        }

        return (!disableEmail && EmailValidator.validate(value)) || (!disableLocalizador && validatorLocalizador.validate(value));
    }

    componentDidMount() {        
        $(() => this._fixClearAfterTyping());
        if(this.props.initialValue) {
            this._handleChange(this.props.initialValue);
        }
    }

    componentDidUpdate() {
        if(this.state.cpfEmailLocalizador===EMPTY_CPF_MASK) {
            this._handleChange("");
        }
    }

    render() {
        return (
            <Input 
                fluid
                children={
                    <div className={`field-email-cpf-localizador ${this.state.cpfEmailLocalizador ? "has-content" : "empty"}`}>
                        <MaskedInput 
                            id={this.props.id}
                            name={this.props.name}
                            onChange={e => this._handleChange(e.target.value)}
                            keepCharPositions="true"                                                                        
                            mask={this._mask}
                            value={this.state.cpfEmailLocalizador}
                        />
                        <div className='ui label label'>{this.props.labelText}</div>
                    </div>  
                }
            />
        );
    }
}

export default EmailCPFLocalizador;

