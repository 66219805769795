/*
** @name: Meu Clínicas - sessionStorageManager
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Agosto 2022
** @description: Prove rotinas para gerenciar os token de integracao no logon do usuário
** 
*/

import { AES, enc, mode, pad } from 'crypto-js';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import { getAppServiceIdByConfigName } from './appServiceList.js';

import packageJson from '../../package.json';


const PARAM_AUTO_OPEN_INTEGRATION = 'aoi';
const PARAM_FIREWALL_INTEGRATION_TOKEN = 'fit';
const PARAM_CUSTOM_LOADING_BG_COLOR = 'clbg';

const _cipherOptions = (key) => {
    return {
        iv: key,
        mode: mode.CBC,
        padding: pad.Pkcs7
    };
}

const _dencryptToken = (cipher, pass) => {
    try {
        const key = _keyFromPass(pass);
        const uncypheredBytes = AES.decrypt(cipher, key, _cipherOptions(key));
        return uncypheredBytes.toString(enc.Utf8);
    } catch(e) {
        return null;
    }
}

const _encryptToken = (token, pass) => {
    try {
        const key = _keyFromPass(pass);
        return AES.encrypt(token, key, _cipherOptions(key)).toString();
    } catch(e) {
        return null;
    }
}

const _getDefaultPass = () => {
    return `${packageJson.appName}:${packageJson.name}`; 
}

const _getQueryStringParam = (pName) => {
    const queryString = window.location.search;
    const sp = new URLSearchParams(queryString);
    const value = sp.get(pName);
    return value ? value : null;
}

const _keyFromPass = (pass) => {
    const key = String(pass + "0000000000000000").substring(0, 16);
    return enc.Utf8.parse(key);
}

const _urlEscapeBase64 = (base64) => {
    return base64.replace(/[+]/g, "-").replace(/\//g, "_").replace(/[=]/g, "*");
}

const _urlUescapeBase64 = (escaped) => {
    return escaped.replace(/[-]/g, "+").replace(/_/g, "/").replace(/[*]/g, "=");
}


class IntegrationToken {
    decode = (token, pacCodigo) => {
        if(!token) {
            return undefined;
        }

        try {
            const unescaped = _urlUescapeBase64(token);
            const defaultPass = _getDefaultPass();
            let plainText = _dencryptToken(unescaped, defaultPass);
            if(!plainText && pacCodigo) {
                plainText = _dencryptToken(unescaped, pacCodigo);
            }
            return plainText;
        } catch(e) {
            return null;
        }
    }

    generate = (module, param, pacCodigo) => {
        if(module && !getAppServiceIdByConfigName(module)) {
            return undefined;
        }
        if(param && !genesysUtils.typeCheck.isObject(param)) {
            return undefined;
        }
        const pass = pacCodigo ? pacCodigo : _getDefaultPass();
        const tokenPayLoad = `${module}${param ? ";" + JSON.stringify(param) : ""}`;

        try {
            const cypher = _encryptToken(tokenPayLoad, pass);
            const escaped = _urlEscapeBase64(cypher);
            return escaped;
        } catch(e) {
            console.error("Erro creating logon integration token", e.message);
            return null;
        }
    }

    getCustomLoadingBgColor = () => {
        const color = (_getQueryStringParam(PARAM_CUSTOM_LOADING_BG_COLOR) || "").replace(/^#*/g, '');
        return /^([0-9a-fA-F]{3}){1,2}$/.test(color) ? `#${color}` : null;
    }

    getIntegrationTokenFromUrl = () => {
        return _getQueryStringParam(PARAM_AUTO_OPEN_INTEGRATION);
    }

    getFirewallIntegrationData = () => {
        try {
            const itkn = genesysUtils.base64.decode(_getQueryStringParam(PARAM_FIREWALL_INTEGRATION_TOKEN));
            return JSON.parse(itkn);
        } catch(e) {
            return null;
        }
    }

    parseToken = (token, pacCodigo) => {
        try {
            const decodedToken = this.decode(token, pacCodigo);
            if(decodedToken) {
                const tokenParts = decodedToken.split(/;(.*)$/s, 2);
                let startModule = getAppServiceIdByConfigName(tokenParts[0]) ? tokenParts[0] : null;
                let tokenParameters = null;
                if(tokenParts[1]) {
                    try {
                        tokenParameters = JSON.parse(tokenParts[1]);
                    } catch(e) { // invalid JSON parameter will be considered as empty
                        console.error('Error parsing auto open module parameters');
                    }
                }
                return { module: startModule, moduleParameters: tokenParameters };
            }
        } catch(e) {
            // ignore any unexpected error
        }
        return {};
    }
}

const integrationToken = new IntegrationToken();
export default integrationToken;
export {
    PARAM_AUTO_OPEN_INTEGRATION
}