import InputField from "../inputField";
import LabelAghuse from "./labelAghuse";
import FieldAghuse from "./fieldAghuse";

import './scss/aghuse.scss';

const InputAghuse = (props) => {
    const { label, description, requiredMark, ...otherProps } = props;

    return (
        <FieldAghuse>
            <LabelAghuse 
                label={label} 
                requiredMark={requiredMark} 
                description={description} 
            />
            <InputField {...otherProps} />
        </FieldAghuse>
    );
};

export default InputAghuse;
