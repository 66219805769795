const LabelAghuse = ({ label, requiredMark, description }) => (
    <div className="label-aghuse">
        
        <span className="label-text">
            {label}
        </span>
        
        <span className="label-additional-info">
            {requiredMark && (
                <i
                    className="aghu-iconeinput aghu-asterisco"
                    title="Este campo é obrigatório"
                ></i>
            )}
            {description && (
                <i
                    aria-hidden="true"
                    className="aghu-iconeinput aghu-interrog"
                    title={description}
                ></i>
            )}
        </span>
    </div>
);

export default LabelAghuse;
