/*
** @name: Meu Clínicas - ativarUsuario
** @author: 
** @date:
** @description: Módulo para ativação do usuario através do link enviado por email
** 
** @update: Março 2021 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Atualizado para o novo layout da aplicação
*/

import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';

import { AppCustomImage, AppCustomMessage } from '@hcpa-react-components/app-customization';
import utils, { EMPTY_HREF } from '../../core/utils.js';
import { useAppControllerContext } from '../../core/appControllerContext.js';
import { getImageThemeContext } from '../../core/appSpecificConfigHandler.js';

import AppExtraDocumentHead from '../../components/general/appExtraDocumentHead/appExtraDocumentHead.js';
import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';

import usuarioClient from '../../apiClients/login/usuarioClient.js';


// Import module styles
import './ativarUsuario.scss'; 

const PARAM_FROM_WIFI = "fwf";
const CONFIG = {
    AGUARDANDO_REENVIO: {
        id: "aguardando-reenvio",
        infoPrincipal: "Novo token de ativação",
        infoAdicional: "Reenviando...",
        img: <AppCustomImage imageContextFn={getImageThemeContext} module="ativarUsuario" imageId="ativacao-verificando" />,
        exibirLinkReenvio: false
    },
    VERIFICANDO: {
        id: "verificando",
        infoPrincipal: "Status de ativação do cadastro",
        infoAdicional: "Verificando...",
        img: <AppCustomImage imageContextFn={getImageThemeContext} module="ativarUsuario" imageId="ativacao-loading" />,
        exibirLinkReenvio: false
    },
    ERRO : {
        id: "erro",
        infoPrincipal: "Erro ao ativar o cadastro",
        infoAdicional: "Não foi possível ativar o seu cadastro",
        img: <AppCustomImage imageContextFn={getImageThemeContext} module="ativarUsuario" imageId="ativacao-erro" />,
        exibirLinkReenvio: true,
        msgErro: ""
    },
    ERRO_REENVIO : {
        id: "erro-reenvio",
        infoPrincipal: "Erro ao reenviar o e-mail",
        infoAdicional: "Não foi possível reenviar um novo e-mail para ativação de cadastro",
        img: <AppCustomImage imageContextFn={getImageThemeContext} module="ativarUsuario" imageId="ativacao-erro" />,
        exibirLinkReenvio: true,
        msgErro: ""
    },
    SUCESSO : {
        id: "sucesso",
        infoPrincipal: "Cadastro ativado",
        infoAdicional: "Seu cadastro foi ativado com sucesso.",
        img: <AppCustomImage imageContextFn={getImageThemeContext} module="ativarUsuario" imageId="ativacao-sucesso" />,
        exibirLinkReenvio: false,
        usuarioValidado: true
    },
    SUCESSO_REENVIO : {
        id: "sucesso-reenvio",
        infoPrincipal: "Nova chave de ativação",
        infoAdicional: "Foi enviado um novo e-mail para a ativação de seu cadastro.",
        img: <AppCustomImage imageContextFn={getImageThemeContext} module="ativarUsuario" imageId="ativacao-sucesso" />,
        exibirLinkReenvio: false
    }
}

const AtivarUsuario = (props) => {
    const appControllerContext = useAppControllerContext();
    return(
        <AtivarUsuarioImplem
            appControllerContext={appControllerContext}
            {...props}
        />
    )
}

class AtivarUsuarioImplem extends Component {

    constructor(props) {
        super(props);
        
        const urlParams = new URLSearchParams(props.location.search);
        this.state = {            
            statusAtivacao: CONFIG.VERIFICANDO,
            urlToken: urlParams.get("tkn")
        } 

        this._verificarStatusAtivacao();
    }

    _handleBackToHome = () => {
        utils.goToHome();
    }

    _isCadastroFromWiFi = () => {
        const sp = new URLSearchParams(this.props.location.search);
        return sp.get(PARAM_FROM_WIFI) === '1';
    }

    _limparMensagensErro = () => {
        CONFIG.ERRO.msgErro = null;
        CONFIG.ERRO_REENVIO.msgErro = null;
    }
        
    _reenviarEmail = () => {
        this._limparMensagensErro();
        this.setState({statusAtivacao: CONFIG.AGUARDANDO_REENVIO });
        usuarioClient.reenviarEmailTokenViaLink(this.state.urlToken, {}, {})
            .then(res => {
                this.setState({
                    statusAtivacao: CONFIG.SUCESSO_REENVIO  
                });               
            })
            .catch(err => {
                if (err.response && err.response.data) {
                    CONFIG.ERRO_REENVIO.msgErro = err.response.data;
                }

                this.setState({
                    statusAtivacao: CONFIG.ERRO_REENVIO  
                });
            });
    }

    _verificarStatusAtivacao = () => {
        this._limparMensagensErro();

        usuarioClient.verificarAtivacaoCadastro(this.state.urlToken, {}, {})
            .then(res => {                
                this.setState({
                    statusAtivacao: CONFIG.SUCESSO,
                });               
            })
            .catch(err => {  
                if (err.response && err.response.data) {
                    CONFIG.ERRO.msgErro = err.response.data;
                }
                this.setState({
                    statusAtivacao: CONFIG.ERRO  
                });
            });
    }
    
    render() {
        const { statusAtivacao } = this.state;
        const isOkButtunEnabled = statusAtivacao.id !== CONFIG.AGUARDANDO_REENVIO.id && statusAtivacao.id !== CONFIG.VERIFICANDO.id;
        const showWiFiMessage = this._isCadastroFromWiFi() && statusAtivacao.usuarioValidado;

        return (
            <div className="ativar-usuario-wrapper">

                <AppExtraDocumentHead subTitle="Ativação de cadastro" robots="noindex,nofollow" />

                <div className="content-wrapper">
                    <div className="content-box">
                        <div className="header">
                            <div className="app-logo">
                                <AppCustomImage imageContextFn={getImageThemeContext} module="ativarUsuario" imageId="logo_app-horizontal" />
                            </div>
                        </div>

                        <div className="body">
                            <Form name="frmMain">
                                <div className="info-principal">
                                    <h2>{statusAtivacao.infoPrincipal}</h2>
                                </div>

                                <div className="status-ativacao">
                                    {statusAtivacao.img}
                                </div>

                                <div className="info-adicional">
                                    <p>
                                        {statusAtivacao.infoAdicional}
                                        {statusAtivacao.msgErro && <span>:</span>}
                                    </p>
                                </div>

                                { statusAtivacao.msgErro &&
                                <AppMessageBox 
                                    id="msg-ativacao-error"
                                    className="error"
                                    messageData={{ message: statusAtivacao.msgErro }} />
                                }

                                { !showWiFiMessage &&
                                <div className="main-action">
                                    <button 
                                        id="button-ok"
                                        className="app-form-button"
                                        disabled={!isOkButtunEnabled}
                                        onClick={() => { this._handleBackToHome() }}>
                                        OK
                                    </button>
                                </div>
                                }

                                { showWiFiMessage && <AppCustomMessage messageId="ativar-usuario_mensagem-wifi" elemType="div" className="mensagem-wifi" /> }

                                {statusAtivacao.exibirLinkReenvio &&
                                <div className="reenviar-email-ativacao">
                                    <a href={EMPTY_HREF} id="link-reenviar-email-ativacao" onClick={(e) => this._reenviarEmail()}>
                                        Clique aqui para enviar um novo e-mail de ativação.
                                    </a>  
                                </div>                                                                 
                                }
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        )   
    }
}

export default AtivarUsuario;