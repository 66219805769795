/*
** @name: Meu Clínicas - appInitializing
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Março 2023
** @description: Componente para renderizar indicativo de inicialização do App
*/

import React from 'react';

import integrationToken from 'core/integrationToken';


// Import module styles
import './appInitializing.scss'; 


const AppInitializing = (props) => {
    const customBgColor = integrationToken.getCustomLoadingBgColor();
    return(
        <div id="initializingWrapperId" style={customBgColor ? { backgroundColor: customBgColor } : null}>
            {props.children}
        </div>
    )
}

export default AppInitializing;