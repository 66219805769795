/*
** @name: Meu Clínicas - compartilharEstudo
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Julho 2021
** @description: Módulo para construir a 'popup' de compartilhamento de estudo
*/

import React, { Component } from 'react';
import { Input } from 'semantic-ui-react';
import QRCode  from 'qrcode.react';
import { pascalCase } from '@hcpa-react-components/string-utils';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';
import { useAppConfigContext } from '@hcpa-react-components/app-customization';

import { useAppControllerContext } from '../../core/appControllerContext.js';
import { getAppServiceSettingsByName } from '../../core/appSpecificConfigHandler.js';
import { APP_SERVICE_LIST } from '../../core/appServiceList.js';

import AppCardModuleBasicWrapper from '../../components/general/appCardModuleBasicWrapper/appCardModuleBasicWrapper.js';


// Import module styles
import './compartilharEstudo.scss'; 


const QR_CODE_DEFAULT_SIZE = 200;
const CLIPBOARD_MESSAGE_TIMEOUT = 5000;

let messageTimeoutId = null;

const CompartilharEstudo = (props) => {
    const appControllerContext = useAppControllerContext();
    const appContextConfig = useAppConfigContext().getContextConfig();
    return(
        <CompartilharEstudoImplem
            appControllerContext={appControllerContext}
            appContextConfig={appContextConfig}
            {...props}
        />
    )
}

class CompartilharEstudoImplem extends Component {
    constructor(props) {
        super(props);

        const { urlCompartilhamento, descricaoEstudo } = this.props.appControllerContext.methods.doGetCurrentCardModuleParameters() || {};

        this.state = {
            urlCompartilhamento: urlCompartilhamento,
            descricaoEstudo: descricaoEstudo,
            copiedToClipboard: null
        };
    }

    _handleClose = () => {
        this.props.appControllerContext.methods.doCardFadeOut();
    }

    _handleCopyURL = () => {
        genesysUtils.general.asyncCopyTextToClipboard(this.state.urlCompartilhamento)
            .then(() => this.setState({ copiedToClipboard: true }))
            .catch(() => this.setState({ copiedToClipboard: false }));
    }

    componentDidMount() {
        const { urlCompartilhamento } = this.state;
        if(!urlCompartilhamento) {
            console.error("URL compartilhamento inválida/não informada");
            this._handleClose();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.copiedToClipboard !== this.state.copiedToClipboard && prevState.copiedToClipboard === null) {
            messageTimeoutId = setTimeout(() => this.setState({ copiedToClipboard: null}), CLIPBOARD_MESSAGE_TIMEOUT);
        }
    }

    componentWillUnmount() {
        if(messageTimeoutId) {
            clearTimeout(messageTimeoutId);
        }
    }

    render() {
        const { urlCompartilhamento, descricaoEstudo, copiedToClipboard } = this.state;
        const serviceConfig = getAppServiceSettingsByName(this.props.appContextConfig, APP_SERVICE_LIST.COMPARTILHAR_ESTUDO);
        const qrCodeSize = serviceConfig && serviceConfig.qrCodeSize ? serviceConfig.qrCodeSize : QR_CODE_DEFAULT_SIZE;
        const exibirExame = serviceConfig.exibirNomeExameEstudo;

        return(
            <AppCardModuleBasicWrapper wrapperName="compartilhar-estudo">

                <div className="sections-wrapper">
                    <div className="primary-section">
                        { (exibirExame && descricaoEstudo) &&
                        <div className="info-row">
                            <div className="titulo">Exame</div>
                            <div className="descricao">{pascalCase(descricaoEstudo)}</div>
                        </div>
                        }

                        <div className="info-row">
                            <div className="message">A partir do endereço abaixo você poderá compartilhar as imagens de todos seus exames. Copie e envie para seu médico.</div>
                        </div>
                    </div>

                    <div className="qrcode-section">
                        <div className="qrcode-wrapper">
                            <div className="qrcode-content">

                                <QRCode 
                                    size={qrCodeSize}
                                    includeMargin={false}
                                    fgColor={"#000000"}
                                    value={urlCompartilhamento} />

                            </div>
                        </div>
                    </div>

                    <div className="section-link">
                        <div className="copyable-field">
                            <Input
                                fluid
                                id="url-link-id"
                                name="utl-compartilhamento"
                                className=""
                                autoComplete="off"
                                value={urlCompartilhamento || ""}
                            />
                        </div>

                        { (copiedToClipboard !== null) &&
                        <div className={`copy-message ${copiedToClipboard ? "" : " error"}`}>
                            {copiedToClipboard ? "URL copiada para área de transferência com sucesso" : "Sem permissão para copiar para área de transferência."}
                        </div>
                        }

                        <div className="action">
                            <div>
                                <button 
                                    className="app-compact-button" 
                                    onClick={() => this._handleCopyURL()}>COPIAR</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="padding-rodape"></div>

            </AppCardModuleBasicWrapper>
        );
    }
}

export default CompartilharEstudo;