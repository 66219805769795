/*
** @name: Meu Clínicas - mainApp
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: November 2022
** @description: Componente para renderizar o flucos principal da aplicação (cards, ajuda, ...)
*/

import React, { useEffect } from 'react';
import { AppCustomMessage, useAppConfigContext } from '@hcpa-react-components/app-customization';

import specialAccessManager from '../../../core/specialAccessManager.js';
import { useAppControllerContext } from '../../../core/appControllerContext.js';
import { useAuthContext } from '../../../core/authContext.js';
import { getCardModuleConfig } from '../../../core/appCardModuleConfig.js';
import integrationToken from '../../../core/integrationToken.js';

import AppCard from '../appCard/appCard.js';
import AppHeader from '../appHeader/appHeader.js';
import AppConfirmationDialog from '../appConfirmationDialog/appConfirmationDialog.js';
import AceiteCookies from '../aceiteCookies/aceiteCookies.js';
import AceiteTermosSessao from '../aceiteTermosSessao/aceiteTermosSessao.js';

import Faq from '../../../modules/faq/faq.js';
import Login from '../../../modules/login/login.js';
import UserHome from '../../../modules/userHome/userHome.js';

import { MODULE_HELP } from './appController.js';


const MainApp = (props) => {
    const { cardModuleList, helpContext, cardFadingOut, showLogoutConfirmation, autoOpenIntegrationToken, loginUpdateControl,
            onAceiteTermos, onBeforeCardClose, onCardClose, onHelpClose, onLogout, onLogoutCanceled } = props;
    const authContext = useAuthContext();
    const appControllerContext = useAppControllerContext();
    const appContextConfig = useAppConfigContext().getContextConfig();
    const isAuth = authContext.methods.isAuthenticated();
    const showHeader = isAuth || (cardModuleList.length>0) || helpContext;
    const hideLogin = (cardModuleList.length>0) || helpContext ? true : false;
    const mostrarTermos = isAuth && !specialAccessManager.user.isUserTermsAccepted();
    const pacCodigo = isAuth ? authContext.properties.user?.pacCodigo : null;
    const addModule = appControllerContext.methods.doAddCardModule;
    const setModule = appControllerContext.methods.doSetCardModule;
    const clearCardModule = appControllerContext.methods.doClearCardModule;
    const useWiFiLayout = appControllerContext.methods.isFromWiFi() && !isAuth;
    const mensagemTermos = () => {
        if(specialAccessManager.user.isUnderRequiredAge()) {
            return null;
        }
        return <AppCustomMessage elemType="div" className="termos-information-message" messageId="_general_mensagem-sugerir-cadastro" />;
    }

    useEffect(() => {
        const { module, moduleParameters } = integrationToken.parseToken(autoOpenIntegrationToken, pacCodigo);
        const addSetModule = moduleParameters?.keepStack ? addModule : setModule;
        if(module) {
            setTimeout(() => addSetModule(module, moduleParameters, false), 100);
        } else if (autoOpenIntegrationToken) {
            clearCardModule();
        }
    }, [autoOpenIntegrationToken, addModule, setModule, clearCardModule, pacCodigo]);

    return(
        <>
            <AceiteCookies />

            { mostrarTermos && 
            <AceiteTermosSessao
                mensagemExtra={mensagemTermos}
                onConfirm={() => onAceiteTermos(true)}
                onCancel={() => onAceiteTermos(false)} />
            }

            { showLogoutConfirmation &&
            <AppConfirmationDialog
                title="Confirmação"
                message="Deseja encerrar a sessão?"
                onConfirm={() => onLogout()}
                onCancel={() => onLogoutCanceled()} />
            }

            { showHeader &&
            <div className="app-viewport-row header">
                <AppHeader showLogoWiFi={useWiFiLayout} />
            </div>
            }

            <div className={`app-viewport-row content${showHeader ? " with-header" : ""}`}>
                { !isAuth ?
                    <Login 
                        hidden={hideLogin}
                        updateControl={loginUpdateControl} 
                        autoOpenIntegrationToken={autoOpenIntegrationToken}
                        showLogoWiFi={useWiFiLayout}
                    />
                :
                <UserHome />
                }
            </div>

            { cardModuleList.map((cardModule, indx) => {
                const { moduleName } = cardModule;
                const cardModuleConfig = getCardModuleConfig(authContext, appContextConfig, moduleName);
                return(
                    <AppCard 
                        key={`cardKey_${indx}`}
                        zIndex={100+(10*indx)}
                        cardSchema={cardModuleConfig.cardSchema}
                        cardTitle={cardModuleConfig.title}
                        fadeOut={cardFadingOut===moduleName}
                        module={cardModuleConfig.module}
                        showCloseAsBack={indx > 0}
                        helpContext={cardModuleConfig.helpContext}
                        onBeforeClose={() => onBeforeCardClose(moduleName)}
                        onClose={() => onCardClose(moduleName)}
                        hideCloseButton={cardModuleConfig.disableCardClose}
                    />
                );
            })}

            { helpContext &&
            <AppCard
                zIndex={1000}
                cardSchema="normal"
                cardTitle={"PRECISA DE AJUDA?"}
                fadeOut={cardFadingOut===MODULE_HELP}
                module={<Faq context={helpContext} />}
                showCloseAsBack={true}
                helpContext={null}
                onBeforeClose={() => true}
                onClose={() => onHelpClose()}
            />
            }
        </>
    );
}

export default MainApp;
