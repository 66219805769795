import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'semantic-ui-react';
import { AppCustomMessage } from '@hcpa-react-components/app-customization';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import utils from '../../core/utils.js';

import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';
import Password from '../../components/fields/password/password.js';

import MainButton from './mainButton.js';
import ExtraActions from './extraActions.js';

import usuarioClient from '../../apiClients/login/usuarioClient.js';


const AUTO_FINALIZAR_TIMEOUT = 5000;

let autoFinalizarTimeoutId = null;

const clearAutoCloseTimeout = () => {
    if(autoFinalizarTimeoutId) {
        clearTimeout(autoFinalizarTimeoutId);
    }
}

const StepTrocaSenha = (props) => {
    const { appContextConfig, loginPass, fromWiFi, passwordChangeCreds, onAjuda, onComplete, onVoltar } = props;
    const [ senha, setSenha ] = useState({});
    const [ novaSenha, setNovaSenha ] = useState({});
    const [ novaSenhaConfirmar, setNovaSenhaConfirmar ] = useState({});
    const [ msgErro, setMsgErro ] = useState(null);
    const [ msgSucesso, setMsgSucesso ] = useState(null);
    const [ disableFinalizar, setDisableFinalizar ] = useState(false);
    const [ fieldToFocus, setFieldToFocus ] = useState(null);

    const _focusFormField = useCallback(() => {
        if(fieldToFocus) {
            document.getElementById(fieldToFocus)?.focus();
            setFieldToFocus(null);
        }
    }, [fieldToFocus]);

    const _getCurrentPass = () => (loginPass || senha.value);

    const _handleFinalizar = () => {
        clearAutoCloseTimeout();
        setDisableFinalizar(true);
        onComplete(novaSenha.value);
    }

    const _handleSenhaChange = (value, valid) => {
        setSenha({ value, valid, errorMessage: null });
        setMsgErro(null);
    }

    const _handleNovaSenhaChange = (value, valid) => {
        setNovaSenha({ value, valid, errorMessage: null });
        setMsgErro(null);
    }

    const _handleNovaSenhaConfirmarChange = (value, valid) => {
        setNovaSenhaConfirmar({ value, valid, errorMessage: null });
        setMsgErro(null);
    }

    const _processaAlteracao = (e) => {
        e.stopPropagation();
        e.preventDefault(); 

        setMsgErro(null);
        usuarioClient.alterarSenhaObrigatoria(_getCurrentPass(), novaSenha.value, novaSenhaConfirmar.value, passwordChangeCreds, {}, {})
            .then(() => {                
                setMsgSucesso({
                    header: "Sucesso!", 
                    message: "Senha alterada."
                });
                autoFinalizarTimeoutId = setTimeout(() => _handleFinalizar(), AUTO_FINALIZAR_TIMEOUT);
            })
            .catch(err => { 
                if (genesysUtils.typeCheck.isString(err.response?.data)) {
                    setMsgErro({
                        header: "Atenção",
                        message: err.response.data
                    });
                } else {
                    setMsgErro({
                        header: "Ops!",
                        message: "Ocorreu um erro ao processar sua requisição."
                    })
                }
                setFieldToFocus(!loginPass ? "inputSenhaId" : "inputNovaSenhaId");
            });
    }

    const _validateForm = () => {
        return _getCurrentPass() && novaSenha.valid;
    }

    useEffect(() => {
        if (!genesysUtils.typeCheck.isFunction(onComplete)) {
            console.error("[Login - Trocar Senha] Missing 'onComplete' function.");
            utils.goToHome();
        }
        setFieldToFocus(!loginPass ? "inputSenhaId" : "inputNovaSenhaId");
        return () => clearAutoCloseTimeout();
    }, [loginPass, onComplete]);

    useEffect(() => _focusFormField(), [fieldToFocus, _focusFormField]);

    return (
        <div className="forca-troca-senha">
            <div className="title">
                Trocar Senha
            </div>

            <div className="instructions">
                <AppCustomMessage messageId="login_forcar-troca-seha" 
                    params={[
                        <AppCustomMessage messageId="login_forcar-troca-seha-geral" />,
                        fromWiFi ? <AppCustomMessage messageId="login_forcar-troca-seha-wifi" /> : ""
                    ]}/>
            </div>

            <Form className="alterar-senha-form">

                { !loginPass &&
                <div className="float-label-field senha">
                    <Password id="inputSenhaId" name="senha" disabled={msgSucesso}
                        className="" darkerInfo={true} labelText="Senha atual" strengthMeter={false}
                        onChange={_handleSenhaChange}
                    />     
                </div>
                }

                <div className="float-label-field nova-senha">
                    <Password id="inputNovaSenhaId" name="novaSenha" disabled={msgSucesso}
                        className="" darkerInfo={true} labelText="Nova senha" strengthMeterAlwaysVisible={!msgSucesso}
                        onChange={_handleNovaSenhaChange}
                    />     
                </div>

                <div className="float-label-field nova-senha-confirmar">
                    <Password id="inputNovaSenhaConfirmarId" name="novaSenhaConfirmar" disabled={msgSucesso}
                        className="" darkerInfo={true} labelText="Confirme a nova senha" strengthMeter={false}
                        onChange={_handleNovaSenhaConfirmarChange}
                    />     
                </div>

                {(msgErro && !msgSucesso) &&
                <AppMessageBox 
                    id="msg-alterar-senha-error" 
                    className="error" 
                    messageData={msgErro} />
                }

                {(!msgErro && msgSucesso) &&
                <AppMessageBox 
                    id="msg-alterar-senha-success" 
                    className="information"
                    messageData={msgSucesso} />
                }  

                <MainButton
                    id="button-action" 
                    type={msgSucesso ? "button" : "default"}
                    wrapperClassName="main-action"
                    className="app-form-button"
                    disabled={msgSucesso ? disableFinalizar : !_validateForm()}
                    onClick={msgSucesso ? () => _handleFinalizar() : e => _processaAlteracao(e)}
                    text={msgSucesso ? "OK" : "Alterar"} />

                <ExtraActions 
                    appContextConfig={appContextConfig}
                    onVoltar={onVoltar}
                    onCadastro={null}
                    onRecuperarSenha={null}
                    onAjuda={onAjuda} />

            </Form> 
        </div>
    );
}

export default StepTrocaSenha;
