/*
** @name: Meu Clínicas - privacyPolicy
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Novembro 2020
** @description: Módulo para os serviços externos (páginas fora do fluxo normal da aplicação)
** para gerar o termos de uso da aplicação fora do fluxo de cadastro
** 
** @update: Março 2021 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Atualizado para novo layout da aplicação e funcionamento com cards e agrupamento por futuras e históricas
**
** @update: Agosto 2024
** @description: Refatoramento para passar os termos para estrutura de mensagens
*/

import React from 'react';

import { AppCustomMessage, useAppConfigContext } from '@hcpa-react-components/app-customization';

import AppExternalServiceHeader from '../../components/general/appExternalServiceHeader/appExternalServiceHeader.js';
import AppExtraDocumentHead from '../../components/general/appExtraDocumentHead/appExtraDocumentHead.js';
import AppMessageBox from '../../components/general/appMessageBox/appMessageBox.js';


// Import module styles
import './privacyPolicy.scss'; 


const PrivacyPolicy = (props) => {
    const isCustomized = useAppConfigContext().isCustomized;

    return(
        <div className="privacy-policy-wrapper">
            <AppExtraDocumentHead subTitle="Termos de uso e privacidade" robots={null} />

            <div className="content">
                <div className="header-card">
                    <AppExternalServiceHeader linkToHome={true}>
                        <h1>Termos de uso e privacidade</h1>
                    </AppExternalServiceHeader>
                </div>

                <div className="body">
                    { isCustomized() ?
                    <AppCustomMessage
                        elemType="html"
                        messageId={[
                            "_general_termos-aceite", 
                            "_general_termos-aceite-complemento-app"
                        ]} 
                        templateJoiner={null} />
                    :
                    <AppMessageBox
                        id="msg-cadastro-error" 
                        className="error" 
                        messageData={{
                            header: 'Ops...',
                            message: <AppCustomMessage messageId="_general_sistema-indisponivel" elemType="fragment" />
                        }} />
                    }
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy;