import DateWithDatePicker from "../dateWithDatePicker";
import LabelAghuse from "./labelAghuse";
import FieldAghuse from "./fieldAghuse";

import './scss/aghuse.scss';

const DatePickerAghuse = (props) => {
    const { label, requiredMark, description, ...otherProps } = props;

    return (
        <FieldAghuse>
            <LabelAghuse 
                label={label} 
                requiredMark={requiredMark} 
                description={description} 
            />
            <DateWithDatePicker {...otherProps} />
        </FieldAghuse>
    );
};

export default DatePickerAghuse;
